import React, { FC } from 'react';

import MediaInfoBox from 'components/common/MediaInfoBox/MediaInfoBox';
import Media from 'components/common/MediaInfoBox/Media';
import Info from 'components/common/MediaInfoBox/Info';

import Image from 'components/common/Image';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import getDropDownItem from 'utils/dropDownControl';
import ImageFixed from 'components/common/Image/ImageFixed';
import PromoContent from './PromoContent';

import { IProductBanner } from './models';

import './ProductBanner.scss';

const ProductBanner: FC<IProductBanner> = ({
  title,
  image,
  imageAlt,
  productImage,
  productImageAlt,
  actionIconName,
  description,
  useFixedProductImage,
}) => (
  <div className="dt-product-promo">
    <MediaInfoBox sectionLandmark={title}>
      <Media>
        <UmbracoImage image={image} alt={imageAlt} />
      </Media>

      <Info>
        <div className="dt-product-promo__promo-image">
          {useFixedProductImage && productImage?.fixedMobilePromoImage ? (
            <ImageFixed
              className="dt-image__product-banner--fixed"
              mobileImage={productImage.fixedMobilePromoImage}
              tabletImage={productImage.fixedTabletPromoImage}
              desktopImage={productImage?.fixedDesktopPromoImage}
              desktopBreakpoint={992}
              alt={productImageAlt}
              lazyLoad={false}
            />
          ) : (
            <Image localImage={productImage} alt={productImageAlt} />
          )}
        </div>
        <PromoContent
          label={{ children: title }}
          text={description}
          iconName={getDropDownItem(actionIconName)}
        />
      </Info>
    </MediaInfoBox>
  </div>
);

export default ProductBanner;
